export const sliderItems = [
    {
        id: 1,
        image: "https://cdn.shopify.com/s/files/1/1679/6063/files/261350_171502489584098_158776627523351_393532_7502736_n_1024x1024.jpg?v=1496329136",
        title: "A SINGLE PASSION.",
    },
    {
        id: 2,
        image: "https://cdn.shopify.com/s/files/1/1679/6063/files/old-bike-barn-cafe-racer-motorcycle-parts-old-bike-barn-2012-january-53435-900x631_1024x1024.jpg?v=1496329168",
        title: "The first time you crack the motor apart and the transmission oil smell comes up and all of a sudden boom, I'm eighteen.",
    },
    {
        id: 3,
        image: "http://caferacertv.com/wp-content/uploads/2015/07/discovery-hd-theater-cafe-racer-caferacer-tv-vintage-motorcycles-ace-cafe-london-rockers-59club-norton-triumph-triton-honda-historic-phot-30.jpg",
        title: "Live intense"
    },
    {
        id: 4,
        image: "https://1.bp.blogspot.com/-bhqCa4pT3PI/U_7hFK8NCdI/AAAAAAAAAOI/vr-tTbgC5rg/s1600/cafe%2Brcer%2Bhistry.jpg",
        title: "Share with your community"
    },
    {
        id: 5,
        image: "http://caferacertv.com/wp-content/uploads/2015/07/discovery-hd-theater-cafe-racer-caferacer-tv-vintage-motorcycles-ace-cafe-london-rockers-59club-norton-triumph-triton-honda-historic-phot-15.jpg",
        title: "Revive your legend inside"
    }
]
export const categories = [
    {
        id: 1,
        category: "accesories",
        image: "https://bsaunitsingles.com/itemimages/BSA/36403R.JPG"
    },
    {
        id: 2,
        category: "bearings",
        image: "https://bsaunitsingles.com/itemimages/BSA/29-3857.JPG"
    },
    {
        id: 3,
        category: "cables",
        image: "https://bsaunitsingles.com/itemimages/BSA/40-8532.JPG"
    },
    {
        id: 4,
        category: "carburetors",
        image: "https://bsaunitsingles.com/itemimages/BSA/60-3536.JPG"
    },
    {
        id: 5,
        category: "clutch",
        image: "https://bsaunitsingles.com/itemimages/BSA/41-3209.JPG"
    },
    {
        id: 6,
        category: "competition",
        image: "https://bsaunitsingles.com/itemimages/BSA/40-9023U.JPG"
    },
    {
        id: 7,
        category: "controls",
        image: "https://bsaunitsingles.com/itemimages/BSA/40-0408.JPG"
    },
    {
        id: 8,
        category: "lights",
        image: "https://bsaunitsingles.com/itemimages/BSA/TAILLIGHT-U.JPG"
    },
    {
        id: 9,
        category: "engine",
        image: "https://bsaunitsingles.com/itemimages/BSA/40-0210U.JPG"
    },
    {
        id: 10,
        category: "exhaust",
        image: "https://bsaunitsingles.com/itemimages/BSA/71-2177R.JPG"
    },
    {
        id: 11,
        category: "fasteners",
        image: "https://bsaunitsingles.com/itemimages/BSA/60-1006U.JPG"
    },
    {
        id: 12,
        category: "fenders",
        image: "https://bsaunitsingles.com/itemimages/BSA/83-2934.JPG"
    },
    {
        id: 13,
        category: "frame",
        image: "https://bsaunitsingles.com/itemimages/BSA/TR5MXFRAME.JPG"
    },
    {
        id: 14,
        category: "gas and oil tanks",
        image: "https://bsaunitsingles.com/itemimages/BSA/82-6887-6888.JPG"
    },
    {
        id: 15,
        category: "head and rocker box",
        image: "https://bsaunitsingles.com/itemimages/BSA/40-0206.JPG"
    },
    {
        id: 16,
        category: "ignition",
        image: "https://bsaunitsingles.com/itemimages/BSA/31899.JPG"
    },
    {
        id: 17,
        category: "instruments",
        image: "https://bsaunitsingles.com/itemimages/BSA/19-8936-R.JPG"
    },
    {
        id: 18,
        category: "pistons and rings",
        image: "https://bsaunitsingles.com/itemimages/BSA/71-1955-JE.JPG"
    },
    {
        id: 19,
        category: "gaskets and seals",
        image: "https://bsaunitsingles.com/itemimages/BSA/97-4002A.JPG"
    },
    {
        id: 20,
        category: "sprockets and chain",
        image: "https://bsaunitsingles.com/itemimages/BSA/47-7720-7729U.JPG"
    },
    {
        id: 21,
        category: "suspension",
        image: "https://bsaunitsingles.com/itemimages/BSA/97-39966ASSY-U(A).JPG"
    },
    {
        id: 22,
        category: "tools",
        image: "https://bsaunitsingles.com/itemimages/BSA/AWSET.JPG"
    },
    {
        id: 23,
        category: "wheels",
        image: "https://bsaunitsingles.com/itemimages/BSA/37-2237.JPG"
    },
]

// export const popularProducts = [
//   {
//     id:1,
//       title: "3 Speed Mx Gear Cluster",
//       img:"https://bsaunitsingles.com/itemimages/BSA/CGB0001.JPG",
//       price: 1175,
//
//   },
//   {
//     id:2,
//       title: "BSA T-shirt",
//     img:"https://cdn.shopify.com/s/files/1/0101/4832/products/Angela_Natural_Tee.png?v=1606780388",
//       price: 30,
//   },
//   {
//     id:3,
//       title: "brake pads",
//     img:"https://www.prada.com/content/dam/pradanux_products/U/UCS/UCS319/1YOTF010O/UCS319_1YOT_F010O_S_182_SLF.png",
//       price: 50,
//   },
//   {
//     id:4,
//       title:"Leather Seats",
//     img:"https://www.burdastyle.com/pub/media/catalog/product/cache/7bd3727382ce0a860b68816435d76e26/107/BUS-PAT-BURTE-1320516/1170x1470_BS_2016_05_132_front.png",
//       price: 300,
//   },
//   {
//     id:5,
//       title:"BSA Flag",
//     img:"https://images.ctfassets.net/5gvckmvm9289/3BlDoZxSSjqAvv1jBJP7TH/65f9a95484117730ace42abf64e89572/Noissue-x-Creatsy-Tote-Bag-Mockup-Bundle-_4_-2.png",
//       price: 25,
//   },
//   {
//     id:6,
//       title:"SS Exhaust",
//     img:"https://d3o2e4jr3mxnm3.cloudfront.net/Rocket-Vintage-Chill-Cap_66374_1_lg.png",
//               price: 250,
//   },
//   {
//     id:7,
//       title:"Fender, Rear 18",
//     img:"https://bsaunitsingles.com/itemimages/BSA/RF18ALLOY.JPG",
//       price: 150,
//   },
//   {
//     id:8,
//       title:"Break Shoes 6",
//     img:"https://bsaunitsingles.com/itemimages/BSA/37-3804.JPG",
//               price: 100,
//   },
// ]